import React from "react";
import verifySession from "../../services/verifySession";

const Control = (props) => {
    const [admin, setAdmin] = React.useState(false);
    verifySession().then(res=>{
        setAdmin(res.data.admin)
    }).catch(err =>{
        setAdmin(false)
    })

    return (
        <>
        {
            admin ? props.children : null
        }
        </>
    )
}

export default Control