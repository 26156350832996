import api from "../api";

const upList = (data, id) => {
    return api.put(`/list/${id}`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export default upList