import React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import addList from '../../../services/ListsAPI/addList';
import upList from '../../../services/ListsAPI/upList';
import { storeList } from '../../../services/StoreAPI/getStores';
import { Select, Option } from '@mui/joy';

import { toast } from 'react-toastify';

const AddAndEditModalCtx = React.createContext();

const AddAndEditModalProvider = ({ children }) => {
    const context = React.useContext(AddAndEditModalCtx)

    return (
        <AddAndEditModalCtx.Provider value={context}>
            {children}
        </AddAndEditModalCtx.Provider>
    )
}

const AddAndEditModal = (props) => {
    const [dataStore, setDataStore] = React.useState([])
    const [dataList, setDataList] = React.useState({})

    React.useEffect(() => {
        storeList().then(resp => {
            setDataStore(resp.data)
        })
    }, [])


    const handleSubmit = () => {
        if (props.typeModal === 'add') {
            addList(dataList).then(resp => {
                toast.success(`${resp.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataList({})
                window.location.reload()
            }).catch(resp => {
                toast.error(`${resp.response.data.error}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataList({})
            })
        } else {
            upList(dataList, props.id).then(resp => {
                toast.success(`${resp.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataList({})
                window.location.reload()
            }).catch(resp => {
                toast.error(`${resp.response.data.error}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataList({})
            })
        }
    }

    return (
        <AddAndEditModalProvider>
            <Modal open={props.open} onClose={() => props.setOpen(false)}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Criação e edição de lista
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Insira as informações
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            props.setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nome</FormLabel>
                                <Input name='name' onChange={(e) => {
                                    setDataList({ ...dataList, [e.target.name]: e.target.value })
                                }} autoFocus />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Loja</FormLabel>
                                <Select onChange={(e, value) => { setDataList({ ...dataList, 'store': value }) }} name='store' placeholder="Loja">
                                    {
                                        dataStore.map((el) => {
                                            return (<Option value={el.name}>{el.name}</Option>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Button type="submit" onClick={handleSubmit}>Adicionar</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </AddAndEditModalProvider>
    )
}

export default AddAndEditModal