import React from "react";
import Layout from "../../components/layout/MainLayout";
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Box from "@mui/joy/Box";
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import { Input } from "@mui/joy";

const Api = () => {


    return (
        <Layout>
            <Sheet
                style={{ marginBottom: '25px' }}
                variant="outlined"
                sx={{
                    borderRadius: 'sm',
                    gridColumn: '1/-1',
                    display: { xs: 'none', sm: 'grid' },
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    '& > *': {
                        p: 2,
                        '&:nth-child(n):not(:nth-last-child(-n+5))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <Typography level="body3" fontWeight="md" noWrap>
                    Token
                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>

                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>

                </Typography>
                <Typography level="body3" fontWeight="md" noWrap style={{ borderBottom: '1px solid #73738c3d' }}>

                </Typography>
                <Input value={localStorage.getItem('token')} style={{ width: '400%' }}></Input>
                <Typography level="body2">
                </Typography>

                <Typography level="body2" >

                </Typography>
                <Typography level="body2" style={{ borderBottom: '1px solid #73738c3d' }}>
                </Typography>
            </Sheet>
            <Sheet
                variant="outlined"
                sx={{
                    display: { xs: 'inherit', sm: 'none' },
                    borderRadius: 'sm',
                    overflow: 'auto',
                    '& > *': {
                        '&:nth-child(n):not(:nth-last-child(-n+4))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <List
                    aria-labelledby="table-in-list"
                    sx={{
                        '& .JoyListItemButton-root': { p: '0px' },
                    }}
                >
                    <>
                        <ListItem>
                            <ListItemButton variant="soft" sx={{ bgcolor: 'transparent' }} onClick={() => { }}>
                                <ListItemContent sx={{ p: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mb: 1,
                                        }}
                                    >
                                        <Typography
                                            level="body2"
                                            sx={{ alignItems: 'flex-start' }}
                                        >
                                            Token
                                        </Typography>
                                        <Typography level="body2">

                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mt: 2,
                                        }}
                                    >
                                        <Box>

                                        </Box>
                                        <Typography level="body2"></Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mb: 1,
                                        }}
                                    >
                                        <Input value={localStorage.getItem('token')} style={{ width: '400%' }}></Input>
                                    </Box>

                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                </List>
            </Sheet>
        </Layout>
    )
}

export default Api;