import React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import addStore from '../../../services/StoreAPI/addStore';
import upStore from '../../../services/StoreAPI/upStore';

import { toast } from 'react-toastify';

const AddAndEditModalCtx = React.createContext();

const AddAndEditModalProvider = ({ children }) => {
    const context = React.useContext(AddAndEditModalCtx)

    return (
        <AddAndEditModalCtx.Provider value={context}>
            {children}
        </AddAndEditModalCtx.Provider>
    )
}

const AddAndEditModal = (props) => {
    const [dataStore, setDataStore] = React.useState({})


    const handleSubmit = () => {
        if (props.typeModal === 'add') {
            addStore(dataStore).then(resp => {
                toast.success(`${resp.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataStore({})
                window.location.reload()
            }).catch(resp => {
                toast.error(`${resp.response.data.error}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataStore({})
            })
        } else {
            upStore(dataStore, props.id).then(resp => {
                toast.success(`${resp.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataStore({})
                window.location.reload()
            }).catch(resp => {
                toast.error(`${resp.response.data.error}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDataStore({})
            })
        }
    }

    return (
        <AddAndEditModalProvider>
            <Modal open={props.open} onClose={() => props.setOpen(false)}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Criação e edição de loja
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Insira as informações
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            props.setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nome</FormLabel>
                                <Input name='name' onChange={(e) => {
                                    setDataStore({ ...dataStore, [e.target.name]: e.target.value })
                                }} autoFocus />
                            </FormControl>
                            <Button type="submit" onClick={handleSubmit}>Adicionar</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </AddAndEditModalProvider>
    )
}

export default AddAndEditModal