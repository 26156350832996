import React, { createContext, useContext } from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Select, Option } from '@mui/joy';
import addDevice from '../../../services/DevicesAPI/addDevices';
import upDevice from '../../../services/DevicesAPI/upDevices';
import { toast } from 'react-toastify';
import { storeList } from '../../../services/StoreAPI/getStores';
import { listLists } from '../../../services/ListsAPI/getLists';

export const AddAndEditModalCtx = createContext();

export const AddAndEditModal = () => {
    const [stores, setStores] = React.useState([])
    const [lists, setLists] = React.useState([])
    const [data, setData] = React.useState({})
    const { open, setOpen, api, idScreen } = useContext(AddAndEditModalCtx);

    React.useEffect(() => {
        storeList().then((resp) => {
            setStores(resp.data)
        })
        listLists().then(resp => {
            setLists(resp.data)
        })
    }, [])

    const clearData = () => {
        setData({})
    }

    const handleSubmit = (e) => {
        console.log(data)
        switch (api) {
            case 'edit':
                upDevice(data, idScreen).then(resp => {
                    toast.success(`${resp.data.msg}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    clearData()
                    window.location.reload()
                }).catch(resp => {
                    toast.error(`${resp.response.data.error}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    clearData()
                })
                break;

            default:
                addDevice(data).then(resp => {
                    toast.success(`${resp.data.msg}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    clearData()
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }).catch(resp => {
                    toast.error(`${resp.response.data.error}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    clearData()
                })
                break;
        }


    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ maxWidth: 500 }}
            >
                <Typography id="basic-modal-dialog-title" component="h2">
                    Adicionar e editar dispositivo
                </Typography>
                <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                    Informe os dados necessários
                </Typography>
                <form
                    onSubmit={(event) => {
                        setOpen(false);
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Nome do dispositivo</FormLabel>
                            <Input onChange={(e) => { setData({ ...data, [e.target.name]: e.target.value }) }} name="name" type="text" autoFocus required />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Link final</FormLabel>
                            <Input onChange={(e) => { setData({ ...data, [e.target.name]: e.target.value }) }} name='link' required />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Lista de conteúdo</FormLabel>
                            <Select placeholder="Escolha a programação" onChange={(e, value) => { setData({ ...data, 'programmation': value }) }}>
                                {
                                    lists.map((el) => {
                                        return (<Option value={el._id}>{el.name}</Option>)
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Qual formato de exibição</FormLabel>
                            <Select onChange={(e, value) => { setData({ ...data, 'rotation': value }) }} name='rotation' placeholder="Orientação">
                                <Option value='vertical'>Vertical</Option>
                                <Option value='horizontal'>Horizontal</Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Loja responsável</FormLabel>
                            <Select onChange={(e, value) => { setData({...data, 'store': value}) }} name='store' placeholder="Loja">
                                {
                                    stores.map((el) => {
                                        return (<Option value={el.name}>{el.name}</Option>)
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Tempo de intervalo</FormLabel>
                            <Select onChange={(e, value) => { setData({...data, 'time': value}) }} name='time' placeholder="Segundos de exibição">
                                <Option value={8000}>8 segundos</Option>
                                <Option value={10000}>10 segundos</Option>
                            </Select>
                        </FormControl>
                        <Button type="submit" onClick={handleSubmit}>Adicionar</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}