import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import { useNavigate } from 'react-router-dom';

// Icons import
import TvIcon from '@mui/icons-material/Tv';
import StorageIcon from '@mui/icons-material/Storage';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StorefrontIcon from '@mui/icons-material/Storefront';
import GroupIcon from '@mui/icons-material/Group';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Control from '../control/controlsession';
import ApiIcon from '@mui/icons-material/Api';

export default function Navigation() {
  const redirect = useNavigate()
  const [select, setSelect] = React.useState('0')

  React.useEffect(() => {
    setSelect(String(localStorage.getItem('menu')))
  }, [])

  return (
    <List size="sm" sx={{ '--List-item-radius': '8px', '--List-gap': '4px' }}>
      <ListItem nested>
        <ListSubheader>
          Menu
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{ '--IconButton-size': '24px', ml: 'auto' }}
          >
            <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        </ListSubheader>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            '& .JoyListItemButton-root': { p: '8px' },
          }}
        >
          <ListItem>
            <ListItemButton variant="soft" color={select === '1' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '1')
              redirect("/")
            }}>
              <ListItemDecorator sx={{ color: 'inherit' }}>
                <TvIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Dispositivos</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton variant="soft" color={select === '2' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '2')
            }}>
              <ListItemDecorator sx={{ color: 'neutral.500' }}>
                <VisibilityIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Monitoramento</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton variant="soft" color={select === '3' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '3')
              redirect('/lists')
            }}>
              <ListItemDecorator sx={{ color: 'neutral.500' }}>
                <StorageIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Lista de conteúdos</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton variant="soft" color={select === '4' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '4')
            }}>
              <ListItemDecorator sx={{ color: 'neutral.500' }}>
                <WatchLaterIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Programações agendadas</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton variant="soft" color={select === '5' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '5')
            }}>
              <ListItemDecorator sx={{ color: 'neutral.500' }}>
                <HelpCenterIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Chamados</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton variant="soft" color={select === '6' ? "primary" : "neutral"} onClick={() => {
              localStorage.setItem('menu', '6')
              redirect('/api')
            }}>
              <ListItemDecorator sx={{ color: 'neutral.500' }}>
                <ApiIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>API</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
      <Control>
        <ListItem nested sx={{ mt: 2 }} >
          <ListSubheader>
            Administração
            <IconButton
              size="sm"
              variant="plain"
              color="primary"
              sx={{ '--IconButton-size': '24px', ml: 'auto' }}
            >
              <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
            </IconButton>
          </ListSubheader>
          <List
            aria-labelledby="nav-list-tags"
            size="sm"
            sx={{
              '--List-decorator-size': '32px',
            }}
          >
            <ListItem>
              <ListItemButton variant="soft" color={select === '7' ? "primary" : "neutral"} onClick={() => {
                localStorage.setItem('menu', '7')
                redirect('/stores')
              }}>
                <ListItemDecorator sx={{ color: 'neutral.500' }}>
                  <StorefrontIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent>Lojas</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton variant="soft" color={select === '8' ? "primary" : "neutral"} onClick={() => {
                localStorage.setItem('menu', '8')
                redirect('/users')
              }}>
                <ListItemDecorator sx={{ color: 'neutral.500' }}>
                  <GroupIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent>Usuários</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton variant="soft" color={select === '9' ? "primary" : "neutral"} onClick={() => {
                localStorage.setItem('menu', '9')
              }}>
                <ListItemDecorator sx={{ color: 'neutral.500' }}>
                  <FolderOpenIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent>Chamados abertos</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton variant="soft" color={select === '10' ? "primary" : "neutral"} onClick={() => {
                localStorage.setItem('menu', '10')
              }}>
                <ListItemDecorator sx={{ color: 'neutral.500' }}>
                  <DoNotDisturbOnIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent>Chamados fechados</ListItemContent>
              </ListItemButton>
            </ListItem>
          </List>
        </ListItem>
      </Control>
    </List>
  );
}
