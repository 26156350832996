import api from "../api";


const addContent = (data) => {
    const form = new FormData();
    form.append("link", data.link);
    form.append("list_id", data.list_id);
    form.append("name", data.name);
    form.append("file", data.file);
    form.append("type", data.type);

    const options = {
        method: 'POST',
        url: '/content',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: form
    };

    return api.request(options)
}

export default addContent