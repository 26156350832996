import api from "../api";

const addDevice = (data) => {
    return api.post('/screen', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export default addDevice