import api from "../api";

const upUser = (data, id) => {
    return api.put(`/user/${id}`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export default upUser