import React from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import List from '@mui/joy/List';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Stack from '@mui/material/Stack';
import addContent from "../../services/ContentsAPI/addContent";
import { toast, ToastContainer } from 'react-toastify';
import { contentsList } from "../../services/ContentsAPI/listContents";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteModal from "./deleteModal";
import host from "../../services/config";

const ContentsModalCtx = React.createContext()

const ContentsModalProvider = ({ children }) => {

    const context = React.useContext(ContentsModalCtx)

    return (
        <ContentsModalCtx.Provider value={context}>
            {children}
        </ContentsModalCtx.Provider>
    )
}

const Contents = (props) => {
    const [contents, setContents] = React.useState([]);
    const [openDel, setOpenDel] = React.useState(false);
    const [idContent, setIdContent] = React.useState('');

    if (props.open === 'fullscreen') {
        contentsList(props.id).then(resp => {
            setContents(resp.data)
        })
    }

    const handleFileData = async (e) => {
        toast('Enviando arquivo', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        addContent({ name: e.target.files[0].name.split('.')[0], link: e.target.files[0].name, 'list_id': props.id, file: e.target.files[0], 'type': e.target.files[0].name.split('.')[1] }).then(resp => {
            toast.success(`${resp.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }).catch(resp => {
            toast.error(`${resp.response.data.error}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })
    }

    return (
        <ContentsModalProvider>
            <Modal open={!!props.open} onClose={() => {
                setContents([])
                props.setOpen('')
            }}>
                <ModalDialog
                    aria-labelledby="layout-modal-title"
                    aria-describedby="layout-modal-description"
                    layout={props.open || undefined}
                >
                    <ToastContainer />
                    <DeleteModal open={openDel} setOpenDel={setOpenDel} id={idContent} />
                    <ModalClose />
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label" style={{ marginBottom: '15px', background: '#0050a6', color: 'white' }}>
                            <UploadFileIcon />
                            <input onChange={handleFileData} hidden type="file" />
                        </Button>
                    </Stack>
                    <Sheet
                        variant="outlined"
                        sx={{
                            borderRadius: 'sm',
                            gridColumn: '1/-1',
                            display: { xs: 'none', sm: 'grid' },
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            '& > *': {
                                p: 2,
                                '&:nth-child(n):not(:nth-last-child(-n+5))': {
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                },
                            },
                        }}
                    >
                        <Typography level="body3" fontWeight="md" noWrap>
                            Nome
                        </Typography>
                        <Typography level="body3" fontWeight="md" noWrap>
                            Conteúdo
                        </Typography>
                        <Typography level="body3" fontWeight="md" noWrap>

                        </Typography>
                        <Typography level="body3" fontWeight="md" noWrap style={{ borderBottom: '1px solid #73738c3d' }}>
                            Editar
                        </Typography>
                        {
                            contents.map((el) => {
                                return (
                                    <>
                                        <Typography
                                            level="body2"
                                            sx={{ alignItems: 'flex-start' }}
                                        >
                                            {el.name}
                                        </Typography>
                                        <Typography level="body2">
                                            <Button component="a" size="sm" href={`${host}/public/${el.link}`} startDecorator={<InsertDriveFileIcon />}>
                                                Visualizar conteúdo
                                            </Button></Typography>
                                        <Typography level="body2" >

                                        </Typography>
                                        <Typography level="body2" style={{ borderBottom: '1px solid #73738c3d' }}>

                                            <Button
                                                color="danger"
                                                disabled={false}
                                                size="sm"
                                                onClick={() => {
                                                    setOpenDel(!openDel)
                                                    setIdContent(el._id)
                                                }}
                                            >{<DeleteForeverIcon />}</Button>
                                        </Typography>
                                    </>
                                )
                            })
                        }
                    </Sheet>
                    <Sheet
                        variant="outlined"
                        sx={{
                            display: { xs: 'inherit', sm: 'none' },
                            borderRadius: 'sm',
                            overflow: 'auto',
                            '& > *': {
                                '&:nth-child(n):not(:nth-last-child(-n+4))': {
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                },
                            },
                        }}
                    >
                        <List
                            aria-labelledby="table-in-list"
                            sx={{
                                '& .JoyListItemButton-root': { p: '0px' },
                            }}
                        >
                            {/* {
                                devices.map((el) => {
                                    return (
                                        <>
                                            <ListItem>
                                                <ListItemButton variant="soft" sx={{ bgcolor: 'transparent' }} onClick={() => { }}>
                                                    <ListItemContent sx={{ p: 2 }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                level="body2"
                                                                startDecorator={<TvIcon color="primary" />}
                                                                sx={{ alignItems: 'flex-start' }}
                                                            >
                                                                {el.name}
                                                            </Typography>
                                                            <Typography level="body2">
                                                                <Button component="a" size="sm" href={`${host}/api/tv.php?view=${el.link}`} startDecorator={<OpenInNew />}>
                                                                    Visualizar tela
                                                                </Button></Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mt: 2,
                                                            }}
                                                        >
                                                            <Box>

                                                            </Box>
                                                            <Typography level="body2">Loja: {el.store}</Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Button
                                                                style={{ marginRight: '25px' }}
                                                                color="primary"
                                                                size="sm"
                                                                disabled={false}
                                                                onClick={function () {
                                                                    setId(el._id)
                                                                    setApi('edit')
                                                                    setopenAddAndEditM(true)
                                                                }}
                                                            >{<CreateIcon />}</Button>
                                                            <DeleteModalCtx.Provider value={{ open: open, setOpen: setOpen, params: el._id }}>
                                                                <DeleteModal />
                                                                <Button
                                                                    color="danger"
                                                                    disabled={false}
                                                                    size="sm"
                                                                    onClick={() => setOpen(!open)}
                                                                >{<DeleteForeverIcon />}</Button>
                                                            </DeleteModalCtx.Provider>
                                                        </Box>

                                                    </ListItemContent>
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )
                                })
                            } */}
                        </List>
                    </Sheet>
                    {/* <Stack direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label">
                            Upload
                            <input hidden accept="image/*" multiple type="file" />
                        </Button>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" />
                            <PhotoCamera />
                        </IconButton>
                    </Stack> */}
                </ModalDialog>
            </Modal>
        </ContentsModalProvider>
    )
}

export default Contents