import React from 'react';
import Layout from '../../components/layout/MainLayout';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Box from "@mui/joy/Box";
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { storeList } from '../../services/StoreAPI/getStores';
import DeleteModal from '../../components/modals/storeModals/deleteStoreModal';
import AddAndEditModal from '../../components/modals/storeModals/storeEditModal';

const Stores = () => {
    const [stores, setStores] = React.useState([])
    const [openDel, setOpenDel] = React.useState(false)
    const [typeModal, setTypeModal] = React.useState('add')
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')

    React.useEffect(() => {
        storeList().then(res => {
            setStores(res.data)
        })
    }, [])

    return (
        <Layout>
            <AddAndEditModal open={open} setOpen={setOpen} typeModal={typeModal} id={id} />
            <DeleteModal open={openDel} setOpenDel={setOpenDel} id={id} />
            <Button
                style={{ marginRight: '25px', marginBottom: '15px' }}
                color="primary"
                size="sm"
                disabled={false}
                onClick={function () {
                    setTypeModal('add')
                    setOpen(!open)
                }}
            >{<AddIcon />}</Button>
            <Sheet
                variant="outlined"
                sx={{
                    borderRadius: 'sm',
                    gridColumn: '1/-1',
                    display: { xs: 'none', sm: 'grid' },
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    '& > *': {
                        p: 2,
                        '&:nth-child(n):not(:nth-last-child(-n+5))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <Typography level="body3" fontWeight="md" noWrap>
                    Nome
                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>

                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>

                </Typography>
                <Typography level="body3" fontWeight="md" noWrap style={{ borderBottom: '1px solid #73738c3d' }}>
                    Editar
                </Typography>
                {
                    stores.map((el) => {
                        return (
                            <>
                                <Typography
                                    level="body2"
                                    startDecorator={<StorefrontIcon color="primary" />}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {el.name}
                                </Typography>
                                <Typography>
                                </Typography>
                                <Typography level="body2">
                                </Typography>
                                <Typography level="body2" style={{ borderBottom: '1px solid #73738c3d' }}>
                                    <Button
                                        style={{ marginRight: '25px' }}
                                        color="primary"
                                        size="sm"
                                        disabled={false}
                                        onClick={function () {
                                            setId(el._id)
                                            setTypeModal('edit')
                                            setOpen(!open)
                                        }}
                                    >{<CreateIcon />}</Button>

                                    <Button
                                        color="danger"
                                        disabled={false}
                                        size="sm"
                                        onClick={() => {
                                            setId(el._id)
                                            setOpenDel(!openDel)
                                        }}
                                    >{<DeleteForeverIcon />}</Button>
                                </Typography>
                            </>
                        )
                    })
                }
            </Sheet>
            <Sheet
                variant="outlined"
                sx={{
                    display: { xs: 'inherit', sm: 'none' },
                    borderRadius: 'sm',
                    overflow: 'auto',
                    '& > *': {
                        '&:nth-child(n):not(:nth-last-child(-n+4))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <List
                    aria-labelledby="table-in-list"
                    sx={{
                        '& .JoyListItemButton-root': { p: '0px' },
                    }}
                >
                    {
                        stores.map((el) => {
                            return (
                                <>
                                    <ListItem>
                                        <ListItemButton variant="soft" sx={{ bgcolor: 'transparent' }} onClick={() => { }}>
                                            <ListItemContent sx={{ p: 2 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        level="body2"
                                                        startDecorator={<StorefrontIcon color="primary" />}
                                                        sx={{ alignItems: 'flex-start' }}
                                                    >
                                                        {el.name}
                                                    </Typography>
                                                    <Typography>

                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Box>

                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Button
                                                        style={{ marginRight: '25px' }}
                                                        color="primary"
                                                        size="sm"
                                                        disabled={false}
                                                        onClick={function () {
                                                            setId(el._id)
                                                            setTypeModal('edit')
                                                            setOpen(!open)
                                                        }}
                                                    >{<CreateIcon />}</Button>
                                                    <Button
                                                        color="danger"
                                                        disabled={false}
                                                        size="sm"
                                                        onClick={() => {
                                                            setId(el._id)
                                                            setOpenDel(!openDel)
                                                        }}
                                                    >{<DeleteForeverIcon />}</Button>
                                                </Box>

                                            </ListItemContent>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        })
                    }
                </List>
            </Sheet>
        </Layout>
    )
}

export default Stores