import React, { createContext, useContext } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';
import deleteDevice from '../../../services/DevicesAPI/delDevices';
import { toast } from 'react-toastify'

export const DeleteModalCtx = createContext();

export const DeleteModal = () => {
  const { open, setOpen, params } = useContext(DeleteModalCtx);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
      >
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<WarningRoundedIcon />}
        >
          Confirmação
        </Typography>
        <Divider />
        <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
          Deseja deletar este dispositivo?
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
          <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button variant="solid" color="danger" onClick={() => {
            deleteDevice(params).then(resp => {
              toast.success(`${resp.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              window.location.reload()
            }).catch(resp => {
              toast.error(`${resp.response.data.error}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            })
            setOpen(false)
          }}>
            Deletar
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}