import api from "../api";

const upDevice = (data, id) => {
    return api.put(`/screen/${id}`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export default upDevice