import api from "../api";

const addStore = (data) => {
    return api.post('/store', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export default addStore