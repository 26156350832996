import React from "react";
import Layout from "../../components/layout/MainLayout";
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import TvIcon from '@mui/icons-material/Tv';
import { devicesList } from "../../services/DevicesAPI/getDevices";
import Button from '@mui/joy/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Box from "@mui/joy/Box";
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteModalCtx, DeleteModal } from "../../components/modals/deviceModals/deleteDeviceModal";
import AddIcon from '@mui/icons-material/Add';
import { AddAndEditModalCtx, AddAndEditModal } from "../../components/modals/deviceModals/deviceEditModal";
import host from "../../services/config";

const Devices = () => {
    const [devices, setDevices] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [api, setApi] = React.useState('add');
    const [id, setId] = React.useState('');
    // const [openMob, setOpenMob] = React.useState(false);
    const [openAddAndEditM, setopenAddAndEditM] = React.useState(false);

    React.useEffect(() => {
        devicesList().then(res => {
            setDevices(res.data);
        })
    }, [])


    return (
        <Layout>
            <AddAndEditModalCtx.Provider value={{ open: openAddAndEditM, setOpen: setopenAddAndEditM, api: api, idScreen: id }}>
                <AddAndEditModal />
            </AddAndEditModalCtx.Provider>
            <Button
                style={{ marginRight: '25px', marginBottom: '15px' }}
                color="primary"
                size="sm"
                disabled={false}
                onClick={function () {
                    setApi('add')
                    setopenAddAndEditM(true)
                }}
            >{<AddIcon />}</Button>
            <Sheet
                variant="outlined"
                sx={{
                    borderRadius: 'sm',
                    gridColumn: '1/-1',
                    display: { xs: 'none', sm: 'grid' },
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    '& > *': {
                        p: 2,
                        '&:nth-child(n):not(:nth-last-child(-n+5))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <Typography level="body3" fontWeight="md" noWrap>
                    Dispositivo
                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>
                    Data de modificação
                </Typography>
                <Typography level="body3" fontWeight="md" noWrap>
                    Loja
                </Typography>
                <Typography level="body3" fontWeight="md" noWrap style={{ borderBottom: '1px solid #73738c3d' }}>
                    Editar
                </Typography>
                {
                    devices.map((el) => {
                        return (
                            <>
                                <Typography
                                    level="body2"
                                    startDecorator={<TvIcon color="primary" />}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {el.name}
                                </Typography>
                                <Typography level="body2">
                                    <Button component="a" size="sm" href={`${host}/view#${el.link}`} startDecorator={<OpenInNew />}>
                                        Visualizar tela
                                    </Button></Typography>
                                <Typography level="body2" >
                                    {el.store}
                                </Typography>
                                <Typography level="body2" style={{ borderBottom: '1px solid #73738c3d' }}>
                                    <Button
                                        style={{ marginRight: '25px' }}
                                        color="primary"
                                        size="sm"
                                        disabled={false}
                                        onClick={function () {
                                            setId(el._id)
                                            setApi('edit')
                                            setopenAddAndEditM(true)
                                        }}
                                    >{<CreateIcon />}</Button>
                                    <DeleteModalCtx.Provider value={{ open: open, setOpen: setOpen, params: el._id }}>
                                        <DeleteModal />
                                        <Button
                                            color="danger"
                                            disabled={false}
                                            size="sm"
                                            onClick={() => setOpen(!open)}
                                        >{<DeleteForeverIcon />}</Button>
                                    </DeleteModalCtx.Provider>
                                </Typography>
                            </>
                        )
                    })
                }
            </Sheet>
            <Sheet
                variant="outlined"
                sx={{
                    display: { xs: 'inherit', sm: 'none' },
                    borderRadius: 'sm',
                    overflow: 'auto',
                    '& > *': {
                        '&:nth-child(n):not(:nth-last-child(-n+4))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
                <List
                    aria-labelledby="table-in-list"
                    sx={{
                        '& .JoyListItemButton-root': { p: '0px' },
                    }}
                >
                    {
                        devices.map((el) => {
                            return (
                                <>
                                    <ListItem>
                                        <ListItemButton variant="soft" sx={{ bgcolor: 'transparent' }} onClick={() => { }}>
                                            <ListItemContent sx={{ p: 2 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        level="body2"
                                                        startDecorator={<TvIcon color="primary" />}
                                                        sx={{ alignItems: 'flex-start' }}
                                                    >
                                                        {el.name}
                                                    </Typography>
                                                    <Typography level="body2">
                                                        <Button component="a" size="sm" href={`${host}/view#${el.link}`} startDecorator={<OpenInNew />}>
                                                            Visualizar tela
                                                        </Button></Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Box>

                                                    </Box>
                                                    <Typography level="body2">Loja: {el.store}</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Button
                                                        style={{ marginRight: '25px' }}
                                                        color="primary"
                                                        size="sm"
                                                        disabled={false}
                                                        onClick={function () {
                                                            setId(el._id)
                                                            setApi('edit')
                                                            setopenAddAndEditM(true)
                                                        }}
                                                    >{<CreateIcon />}</Button>
                                                    <DeleteModalCtx.Provider value={{ open: open, setOpen: setOpen, params: el._id }}>
                                                        <DeleteModal />
                                                        <Button
                                                            color="danger"
                                                            disabled={false}
                                                            size="sm"
                                                            onClick={() => setOpen(!open)}
                                                        >{<DeleteForeverIcon />}</Button>
                                                    </DeleteModalCtx.Provider>
                                                </Box>

                                            </ListItemContent>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        })
                    }
                </List>
            </Sheet>
        </Layout>
    )
}

export default Devices;