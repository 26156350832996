import React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';
import deleteList from '../../../services/ListsAPI/delList';
import { toast } from 'react-toastify';

const DeleteModalCtx = React.createContext()

const DeleteModalProvider = ({ children }) => {
  const context = React.useContext(DeleteModalCtx)

  return (
    <DeleteModalCtx.Provider value={context}>
      {children}
    </DeleteModalCtx.Provider>
  )
}

const DeleteModal = (props) => {
  const handleDelete = () => {
    deleteList(props.id).then(resp => {
      props.setOpenDel(false)
      toast.success(`${resp.data.msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload()
    }).catch(resp => {
      toast.error(`${resp.response.data.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  }

  return (
    <DeleteModalProvider>
      <Modal open={props.open} onClose={() => props.setOpenDel(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Confirmação
          </Typography>
          <Divider />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Deseja deletar esta lista?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={() => props.setOpenDel(false)}>
              Cancelar
            </Button>
            <Button variant="solid" color="danger" onClick={handleDelete}>
              Deletar
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </DeleteModalProvider>
  )
}



export default DeleteModal;