import axios from 'axios';
import host from './config';

const api = axios.create({
    baseURL:`${host}`,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    httpsAgent: ''
});

export default api;