import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/login/index';
import Devices from '../pages/devices/index';
import Users from '../pages/users';
import Stores from '../pages/stores';
import Api from '../pages/api';
import Lists from '../pages/lists';
import verifySession from '../services/verifySession';

const RoutesController = () => {
    const [validate, setValidate] = React.useState(false);
    const [admin, setAdmin] = React.useState(false);

    verifySession().then(res => {
        setValidate(true)
        setAdmin(res.data.admin)
    }).catch(res => {
        setValidate(false)
        setAdmin(false)
    })


    return (
        <BrowserRouter>
            <Routes>
                <Route exec path='/login' element={validate ? <Navigate to='/' /> : <Login />} />
                <Route exec path='/' element={validate ? <Devices /> : <Login />} />
                <Route exec path='/lists' element={validate ? <Lists /> : <Login />} />
                <Route exec path='/api' element={validate ? <Api /> : <Login />} />
                {
                    admin ?
                        (
                            <>
                                <Route exec path='/users' element={validate ? <Users /> : <Login />} />
                                <Route exec path='/stores' element={validate ? <Stores /> : <Login />} />
                            </>
                        )
                        : (
                            <>
                                <Route exec path='/users' element={validate ? <Devices /> : <Login />} />
                                <Route exec path='/stores' element={validate ? <Stores /> : <Login />} />
                            </>
                        )
                }
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesController;